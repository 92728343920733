@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Aleo:wght@400;500;600;700;800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Great+Vibes&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;



.font-springbold {
  font-family: fontspringbold;
}
button {
  border-radius: 2px !important;
  border-style: none;
  cursor: pointer;
  position: relative;
  padding: 0px;
  overflow: hidden;
  transition: all 0.5s;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
button span {
  position: absolute;
  display: block;
}
button span:nth-child(1) {
  height: 5px;
  width: 200px;
  top: 0px;
  left: -200px;
  background: linear-gradient(to right, rgba(0, 0, 0, 0), #6ed2e9);
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
  animation: span1 2s linear infinite;
  animation-delay: 1s;
}

@keyframes span1 {
  0% {
    left: -200px;
  }
  100% {
    left: 200px;
  }
}
button span:nth-child(2) {
  height: 70px;
  width: 5px;
  top: -70px;
  right: 0px;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), #6ed2e9);
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  animation: span2 2s linear infinite;
  animation-delay: 2s;
}
@keyframes span2 {
  0% {
    top: -70px;
  }
  100% {
    top: 70px;
  }
}
button span:nth-child(3) {
  height: 5px;
  width: 200px;
  right: -200px;
  bottom: 0px;
  background: linear-gradient(to left, rgba(0, 0, 0, 0), #6ed2e9);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  animation: span3 2s linear infinite;
  animation-delay: 3s;
}
@keyframes span3 {
  0% {
    right: -200px;
  }
  100% {
    right: 200px;
  }
}

button span:nth-child(4) {
  height: 70px;
  width: 5px;
  bottom: -70px;
  left: 0px;
  background: linear-gradient(to top, rgba(0, 0, 0, 0), #6ed2e9);
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  animation: span4 2s linear infinite;
  animation-delay: 4s;
}
@keyframes span4 {
  0% {
    bottom: -70px;
  }
  100% {
    bottom: 70px;
  }
}

button:hover {
  transition: all 0.5s;
  transform: rotate(-3deg) scale(1.1);
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.4);
}
button:hover span {
  animation-play-state: paused;
}

/* all Courses css here */

.circle-container {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 0 auto;
}

.circle-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  animation: rotateAnimation 2s infinite linear;
}

@keyframes rotateAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.image {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
}

.circle-image:hover {
  animation: hoverAnimation 3s infinite ease-in-out;
}

@keyframes hoverAnimation {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.about_us_color {
  background: linear-gradient(222.96deg, #231e62 30.11%, #3671ca 56.8%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.animation {
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;
}
@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* new team section css here  */
.profile-card {
  position: relative;
  font-family: sans-serif;
  width: 220px;
  height: 220px;
  background: #fff;
  padding: 15px;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
  margin: 0 25px;
  margin-top: 60px;
}
.profile-card:hover {
  border-radius: 10px;
  height: 260px;
}
.profile-card .img {
  position: relative;
  width: 100%;
  height: 100%;
  transition: 0.6s;
  z-index: 99;
}
.profile-card:hover .img {
  transform: translateY(-60px);
}
.img img {
  width: 100%;
  border-radius: 50%;
  box-shadow: 0 0 22px #3336;
  transition: 0.6s;
}
.profile-card:hover img {
  border-radius: 10px;
}
.caption {
  text-align: center;
  transform: translateY(-80px);
  opacity: 0;
  transition: 0.6s;
}
.profile-card:hover .caption {
  opacity: 1;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.Toastify__toast-container--top-right {
  z-index: 99999999 !important;
}

/* Loader Style Start*/
.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #183153;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
/* Loader Style End */
